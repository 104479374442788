<template>
  <div>
    <nav-bar-pref></nav-bar-pref>
    <b-container class="secretaries-container">
      <h1>Gabinete</h1>
      <b-card-group>
        <b-card 
          :title="gabinete.nome"
          :img-src="gabinete.img"
          :img-left="imgLeft"
          img-width="300"
          class="gabinete"
        >
          <b-card-text>
            <div class="secre-card-info">
              <div class="secre-text-icon">
                <span class="bold d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-user"></i>
                  </div>
                  {{ gabinete.resp }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-user-tag"></i>
                  </div>
                  {{ gabinete.cargo }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-graduation-cap"></i>
                  </div>
                  {{ gabinete.graduacao }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  {{ gabinete.endereco }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap"><i class="fas fa-at"></i></div>
                  {{ gabinete.email }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-phone-alt"></i>
                  </div>
                  {{ gabinete.tel }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-clock"></i>
                  </div>
                  {{ gabinete.hora }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-file"></i>
                  </div>
                    <a href="https://ribas-publications.s3.sa-east-1.amazonaws.com/ESTRUTURA-ADMINISTRATIVA-2023.pdf"><strong>Atribuições do Gabinete</strong></a>
                      {{ gabinete.atrib }}</span
                >
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-card-group>
      <h1>Vice-Prefeita</h1>
      <b-card-group>
        <b-card
          :title="vice.nome"
          :img-src="vice.img"
          :img-left="imgLeft"
          img-width="300"
        >
          <b-card-text>
            <div class="secre-card-info">
              <div class="secre-text-icon">
                <span class="bold d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-user"></i>
                  </div>
                  {{ vice.resp }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-user-tag"></i>
                  </div>
                  {{ vice.cargo }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-graduation-cap"></i>
                  </div>
                  {{ vice.graduacao }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  {{ vice.endereco }}</span
                >
              </div>
              <div class="secre-text-icon">
                <!-- <span class="d-flex"
                  ><div class="secre-icon-wrap"><i class="fas fa-at"></i></div>
                  {{ vice.email }}</span
                > -->
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-phone-alt"></i>
                  </div>
                  {{ vice.tel }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-clock"></i>
                  </div>
                  {{ vice.hora }}</span
                >
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-card-group>
      <h1>Secretarias</h1>
      <b-card-group>
        <b-card
          v-for="secretaria in secretarias"
          :key="secretaria.nome"
          :title="secretaria.nome"
          :img-src="secretaria.img"
          :img-left="imgLeft"
          img-width="300"
        >
          <b-card-text>
            <div class="secre-card-info">
              <div class="secre-text-icon">
                <span class="bold d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-user"></i>
                  </div>
                  {{ secretaria.resp }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-user-tag"></i>
                  </div>
                  {{ secretaria.cargo }}</span
                >
              </div>
              <div v-if="secretaria.graduacao" class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-graduation-cap"></i>
                  </div>
                  {{ secretaria.graduacao }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  {{ secretaria.endereco }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap"><i class="fas fa-at"></i></div>
                  {{ secretaria.email }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-phone-alt"></i>
                  </div>
                  {{ secretaria.tel }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-clock"></i>
                  </div>
                  {{ secretaria.hora }}</span
                >
              </div>
              <div class="secre-text-icon">
                <span class="d-flex"
                  ><div class="secre-icon-wrap">
                    <i class="fas fa-file"></i>
                  </div>
                    <a href="https://ribas-publications.s3.sa-east-1.amazonaws.com/ESTRUTURA-ADMINISTRATIVA-2023.pdf"><strong>Atribuições da Secretaria</strong></a>
                      {{ secretaria.atrib }}</span
                >
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-card-group>
    </b-container>
    <footer-pref></footer-pref>
  </div>
</template>
<script>
import FooterPref from "../components/FooterPref.vue";
import NavBarPref from "../components/NavBarPref.vue";
export default {
  data() {
    return {
      imgLeft: true,
      gabinete: {
        nome: "Gabinete do Prefeito",
        resp: "JOÃO ALFREDO DANIEZE - PREFEITO",
        cargo: "Prefeito",
        graduacao: "Direito - FMU - São Paulo - SP, 1988.",
        endereco: "Rua Conceição do Rio Pardo, 1725, Centro - Cep: 79180-000",
        email: "prefeitura@ribasdoriopardo.ms.gov.br",
        tel: "0800-808-1175",
        hora: "Seg à Qui: 7h-11h - 13h-17:15h / Sex: 7h-11h - 13h-16h",
        img: "https://ribas-publications.s3.sa-east-1.amazonaws.com/user-solid.svg",
      },
      vice: {
        nome: "Vice-Prefeita - IN MEMORIAM",
        resp: "GUIOMAR SOARES DOS SANTOS ★30/09/1965 ✟12/03/2023",
        cargo: "Vice-Prefeita",
        graduacao: "Pedagogia - UCDB",
        endereco: "Rua Conceição do Rio Pardo, 1725, Centro - Cep: 79180-000",
        //email: "viceprefeito@ribasdoriopardo.ms.gov.br",
        tel: "0800-808-1175",
        hora: "Seg à Qui: 7h-11h - 13h-17:15h / Sex: 7h-11h - 13h-16h",
        img: "https://diribas.s3.sa-east-1.amazonaws.com/GUIOMAR%2BSOARES%2BDOS%2BSANTOS%2B1225-3.png",
      },
      secretarias: [
        {
          nome: "SEGOV - Secretaria Municipal de Gestão de Governo",
          resp: "MANOEL APARECIDO DOS ANJOS",
          cargo: "Secretário Municipal de Gestão de Governo",
          graduacao: "Administração - UCDB/ANHANGUERA",
          endereco: "Rua Conceição do Rio Pardo, 1725, Centro - Cep: 79180-000",
          email: "administracao@ribasdoriopardo.ms.gov.br",
          tel: "0800-808-1175",
          hora: "Seg à Qui: 7h-11h - 13h-17:15h / Sex: 7h-11h - 13h-16h",
          img: "https://ribas-publications.s3.sa-east-1.amazonaws.com/WhatsApp+Image+2022-08-25+at+09.20.58.jpeg",
        },
        {
          nome: "SAS - Secretaria Municipal de Assistência Social e Habitação",
          resp: "ÉRICA JURADO FERNANDES",
          cargo: "Secretária Municipal de Assistência Social e Habitação",
          graduacao: "Magistério – Colégio Presbiteriano Mackenzie (SP) / Gestão Comercial – SENAC (SP) / Acadêmica de Direito – PUC (SP) (a concluir)",
          endereco: "Rua Conceição do Rio Pardo, 1886, Centro - Cep: 79180-000",
          email: "assistencia@ribasdoriopardo.ms.gov.br",
          tel: "0800-808-1175",
          hora: "Seg à Qui: 7h-11h - 13h-17:15h / Sex: 7h-11h - 13h-16h",
          img: "https://diribas.s3.sa-east-1.amazonaws.com/ERICA_JURADO.jpg",
        },
        {
          nome: "SEMP - Secretaria Municipal de Empreendedorismo",
          resp: "CLÁUDIO PEREIRA DA SILVA",
          cargo: "Secretário Municipal de Empreendedorismo",
          graduacao: "Ciências Biológicas - UFMS",
          endereco: "Rua Cornélia Anconi Bunazar, 1.638, Jardim Vista Alegre - Cep: 79182-020",
          email: "desenvolvimento@ribasdoriopardo.ms.gov.br",
          tel: "0800-808-1175",
          hora: "Seg à Qui: 7h-11h - 13h-17:15h / Sex: 7h-11h - 13h-16h",
          img: "https://diribas.s3.sa-east-1.amazonaws.com/CLAUDIO_PEREIRA_DA_SILVA.jpg",
        },
        {
          nome: "SED - Secretaria Municipal de Educação",
          resp: "NIZAEL FLÔRES DE ALMEIDA",
          cargo: "Secretário Municipal de Educação",
          graduacao: "Letras - UNIGRAN",
          endereco:
            "Rua Júlio José dos Santos, 71, Nossa Senhora da Conceição - Cep: 79182-100",
          email: "educacao@ribasdoriopardo.ms.gov.br",
          tel: "0800-808-1175",
          hora: "Seg à Qui: 7h-11h - 13h-17:15h / Sex: 7h-11h - 13h-16h",
          img: "https://ribas-publications.s3.sa-east-1.amazonaws.com/NIZAEL+ALMEIDA+4720-1.png",
        },
        {
          nome: "SEFIP - Secretaria Municipal de Finanças e Planejamento",
          resp: "NADJA DE LIMA MATIAS",
          cargo: "Secretária Municipal de Finanças e Planejamento",
          graduacao: "Matemática - UCDB",
          endereco: "Rua Conceição do Rio Pardo, 1725, Centro - Cep: 79180-000",
          email: "financas@ribasdoriopardo.ms.gov.br",
          tel: "0800-808-1175",
          hora: "Seg à Qui: 7h-11h - 13h-17:15h / Sex: 7h-11h - 13h-16h",
          img: "https://ribas-publications.s3.sa-east-1.amazonaws.com/nadja.jpeg",
        },
        {
          nome: "SESP - Secretaria Municipal de Esportes e Turismo",
          resp: "JÚLIO NOGUEIRA",
          cargo: "Secretário Municipal de Esportes e Turismo",
          //graduacao: "Educação Física - ANHANGUERA",
          endereco:
            "R. Álvaro Jobim, 1288, Vila Nossa Sra. da Conceicao - Cep: 79180-000",
          email: "sejel@ribasdoriopardo.ms.gov.br",
          tel: "0800-808-1175",
          hora: "Seg à Qui: 7h-11h - 13h-17:15h / Sex: 7h-11h - 13h-16h",
          img: "https://ribas-publications.s3.sa-east-1.amazonaws.com/JULIO+NOGUEIRA.JPG",
        },
        {
          nome: "SEINFRA - Secretaria Municipal de Infraestrutura Pública",
          resp: "ANTÔNIO CELSO RODRIGUES SILVA JÚNIOR",
          cargo: "Secretário Municipal de Infraestrutura Pública",
          graduacao: "Educação Física - ANHANGUERA",
          endereco:
            "Av. Nelson Lyrio, 2452, Centro - Cep: 79180-000",
          email: "obras@ribasdoriopardo.ms.gov.br",
          tel: "0800-808-1175",
          hora: "Seg à Qui: 7h-11h - 13h-17:15h / Sex: 7h-11h - 13h-16h",
          img: "https://ribas-publications.s3.sa-east-1.amazonaws.com/CELSO+J%C3%9ANIOR+3468-2.png",
        },
        {
          nome: "SESAU - Secretaria Municipal de Saúde",
          resp: "MARYANE HIRAHATA SHIOTA",
          cargo: "Secretária Municipal de Saúde",
          graduacao: 
            "Fisioterapia - FCG (MS) / Gestão da Saúde com Ênfase em Auditoria - Estácio de Sá (MS)",
          endereco: "Rua Conceição do Rio Pardo, 1872, Centro - Cep: 79180-000",
          email: "saude@ribasdoriopardo.ms.gov.br",
          tel: "0800-808-1175",
          hora: "Seg à Qui: 7h-11h - 13h-17:15h / Sex: 7h-11h - 13h-16h",
          img: "https://ribas-publications.s3.sa-east-1.amazonaws.com/MARYANE+HIRAHATA+SHIOTA.jpg",
        },
      ],
    };
  },
  components: {
    FooterPref,
    NavBarPref,
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      window.innerWidth > 768 ? (this.imgLeft = true) : (this.imgLeft = false);
    },
  },
};
</script>
<style lang="scss">
.secretaries-container {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Raleway;
  h1 {
    font-weight: bold;
    text-align: initial;
    margin-top: 20px;
    margin-bottom: 20px;
    color: black;
  }
  .card-group {
    flex-direction: column;
    text-align: initial;
    .secre-text-icon {
      display: flex;
      flex-direction: column;
      .secre-icon-wrap {
        width: 20px;
        margin-right: 10px;
      }
      .bold {
        font-weight: bold;
      }
    }
    .card {
      margin-bottom: 20px;
      object-fit: cover;
      border-radius: 10px;
      word-break: break-word;
      img {
        object-fit: cover;
        height: 250px;
        @media (max-width: 952px) {
          height: 100%;
        }
        @media (max-width: 767px) {
          height: 350px;
        }
      }
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
    .gabinete {
      img {
        object-fit: scale-down;
        height: 275px;
        background-color: #e4e4e4cc;
        @media (max-width: 992px) {
          min-width: 300px;
        }
        @media (max-width: 400px) {
          min-width: inherit;
        }
      }
    }
    .card-title {
      font-weight: bold;
    }
    .card-body {
      @media (max-width: 460px) {
        padding: 0.7rem;
      }
    }
  }
}
</style>
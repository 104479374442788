import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router'
import _ from 'lodash'
import BootstrapVue from 'bootstrap-vue';
import Bootstrap from 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-free/js/all'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from './router'
import VueExpandableImage from 'vue-expandable-image'
import VueCookies from 'vue-cookies';
import VueYoutube from 'vue-youtube';
import VueSocialSharing from 'vue-social-sharing'
import loader from "vue-ui-preloader";
import VueMeta from 'vue-meta'

library.add(faUserSecret)
Vue.component('fa-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

Vue.prototype.$diribasPage = 0;
Vue.prototype.$diribasText = '';
Vue.prototype.$diribasSize = 18;

Vue.use(BootstrapVue);
Vue.use(Bootstrap);
Vue.use(VueRouter);
Vue.use(_);
Vue.use(VueExpandableImage);
Vue.use(VueCookies);
Vue.use(VueYoutube);
Vue.use(VueSocialSharing);
Vue.use(loader);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
